export default function cart_sku_validation() {
    var prohibited_items_in_cart = false;
    var checking_items_in_cart = false;
    async function check_cart(){
        checking_items_in_cart = true;
        var prohibited_states = ["TX","TN","OH","MO","IA","LA","MA"];
        var prohibited_skus_shipping = ["W0293", "DW103", "DW203", "W0167", "W0220", "W0237", "DW215", "W0198", "W0259"];
        var prohibited_skus_pickup = ["W0273", "W0274", "W0275","W0293", "DW103", "DW203", "W0167", "W0220", "W0237", "DW215", "W0198", "W0259"];

        // Get cart ID
        function getCookie(name) {
        var cookie = {};
        document.cookie.split(';').forEach(function(el) {
            let [k,v] = el.split('=');
            cookie[k.trim()] = v;
        })
        return cookie[name];
        }

        var cart_id = getCookie("cartId");

        var cart_information = await fetch("https://api.commerce7.com/v1/cart/"+cart_id, { 
            method: 'GET', 
            headers: { 
            'Content-type': 'application/json',
                'Tenant':'mumm-napa'
            }
        }); 

        var cart_json = await cart_information.json();
        var items_removed = false;
        var delete_sku;
        var selected_state = "XX";
        var state_selector = document.querySelector("select[name='stateCode']");
        var account_addresses = document.querySelectorAll(".c7-form__field.c7-form--shipping-address .c7-form__field .c7-radio.c7-radio--box input");
        var account_address_selected = "";
        var account_address_selected_content;
        var selected_address_state = "";
        for(var i=0;i<account_addresses.length;i++){
            if(account_addresses[i].checked){
                account_address_selected = account_addresses[i].parentElement.querySelector(".c7-radio__label__description")
                account_address_selected_content = account_address_selected.getInnerHTML().split("<br>")[1].split(" ")
                selected_address_state = account_address_selected_content[account_address_selected_content.length-2]
            }
        }
        if(selected_address_state){
            selected_state = selected_address_state
        }
        console.log(state_selector)
        if(state_selector){
            console.log(state_selector)
            console.log(state_selector.selectedOptions[0]['text'])
            console.log(state_selector.value)
            try {
                console.log(state_selector.value)
                selected_state = document.querySelector("select[name='stateCode']").value
            } catch (error) {
                console.error(error)
            }
        }
        if ((cart_json.shipTo && prohibited_states.includes(cart_json.shipTo.stateCode)) || (window.location.href.includes('/checkout/cart/delivery') && prohibited_states.includes(selected_state))){
            for (let i=0;i< cart_json.items.length;i++){
                if (prohibited_skus_shipping.includes(cart_json.items[i].sku)){
                delete_sku = await fetch("https://api.commerce7.com/v1/cart/"+cart_id+"/item/"+cart_json.items[i].sku, { 
                    method: 'DELETE', 
                    headers: { 
                        'Content-type': 'application/json',
                        'Tenant':'mumm-napa'
                    }
                    }); 
                    items_removed = true;
                }
            }
            if (items_removed){
                prohibited_items_in_cart = true;
                checking_items_in_cart = false;
                alert("To comply with state laws, some items were removed from your cart.");
                location.reload();
                return false;
            }
        }
        if (cart_json.pickupBy != null){
            for (let i=0;i< cart_json.items.length;i++){
                if (prohibited_skus_pickup.includes(cart_json.items[i].sku)){
                delete_sku = await fetch("https://api.commerce7.com/v1/cart/"+cart_id+"/item/"+cart_json.items[i].sku, { 
                    method: 'DELETE', 
                    headers: { 
                        'Content-type': 'application/json',
                        'Tenant':'mumm-napa'
                    }
                    }); 
                    items_removed = true;
                }
            }
            if (items_removed){
                checking_items_in_cart = false;
                alert("To comply with state laws, some items were removed from your cart.");
                location.reload();
                return false;
            }
        }
        checking_items_in_cart = false;
        return true;
    }
    function waitForElement(selector, callback) {
        const element = document.querySelector(selector);
        
        if (element) {					
            callback(element);
        } else {
            setTimeout(() => {
                waitForElement(selector,callback);
            }, 100); // Adjust the delay (in milliseconds) between checks if needed
        }
    }
    function waitForShipcheckout() {
        if (window.location.href.includes('/checkout/cart/delivery')){
                const continue_button = document.querySelector(".c7-checkout__main .c7-checkout__content .c7-form__buttons .c7-btn.c7-btn--primary");
                if (continue_button) {					
                    continue_button.style.display = "none";
                    var replacement_button = document.querySelector(".c7-btn.c7-btn--primary.replacement")
                    if (replacement_button == null){
                        continue_button.parentElement.innerHTML = continue_button.parentElement.innerHTML + '<a onclick="cart_sku_validation.check_cart().then((check_order_result) => {if(check_order_result){var continue_button = document.querySelector(\'.c7-checkout__main .c7-checkout__content .c7-form__buttons .c7-btn.c7-btn--primary\'); continue_button.click()}})" class="c7-btn c7-btn--primary replacement" formnovalidate=""><span>Continue to Payment</span></a>'
                    }
                }
        }
        setTimeout(() => {
            waitForShipcheckout();
        }, 100); // Adjust the delay (in milliseconds) between checks if needed
    }
    function checkCartForever(){
        if(window.location.href.includes('/checkout/cart/') && checking_items_in_cart == false && prohibited_items_in_cart != true){
            check_cart();
        }
        setTimeout(() => {
            checkCartForever();
        }, 300);
    }
    checkCartForever();
    if (window.location.href.includes('/checkout/cart/')){
        console.log("checkCartForever");
        checkCartForever();
        if (window.location.href.includes('/checkout/cart/delivery')){
            waitForElement((".c7-checkout__main .c7-checkout__content .c7-form__buttons .c7-btn.c7-btn--primary"), (continue_button) => {												
                continue_button.style.display = "none";
                continue_button.parentElement.innerHTML = continue_button.parentElement.innerHTML + '<a onclick="cart_sku_validation.check_cart().then((check_order_result) => {if(check_order_result){var continue_button = document.querySelector(\'.c7-checkout__main .c7-checkout__content .c7-form__buttons .c7-btn.c7-btn--primary\'); continue_button.click()}})" class="c7-btn c7-btn--primary replacement" formnovalidate=""><span>Continue to Payment</span></a>';
            });
        }
        var oldHref = document.location.href;

        var bodyList = document.querySelector("body")

        var observer = new MutationObserver(function(mutations) {
            if (oldHref != document.location.href) {
                oldHref = document.location.href;
                if (window.location.href.includes('/checkout/cart/review') || window.location.href.includes('/checkout/cart/payment')){
                    check_cart();
                }
                if (window.location.href.includes('/checkout/cart/delivery')){
                    waitForElement((".c7-checkout__main .c7-checkout__content .c7-form__buttons .c7-btn.c7-btn--primary"), (continue_button) => {												
                        continue_button.style.display = "none";
                        continue_button.parentElement.innerHTML = continue_button.parentElement.innerHTML + '<a onclick="cart_sku_validation.check_cart().then((check_order_result) => {if(check_order_result){var continue_button = document.querySelector(\'.c7-checkout__main .c7-checkout__content .c7-form__buttons .c7-btn.c7-btn--primary\'); continue_button.click()}})" class="c7-btn c7-btn--primary replacement" formnovalidate=""><span>Continue to Payment</span></a>'
                    });
                }
            }
        });
        
        var config = {
            childList: true,
            subtree: true
        };
        
        observer.observe(bodyList, config);

        waitForShipcheckout();
    }
    window.cart_sku_validation = {};
	window.cart_sku_validation.check_cart = check_cart;

}
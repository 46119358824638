

export default function checkout() {
    function isCommerce7V2(){
        const element = document.querySelector("#c7-javascript");
        return element.src.includes("v2")
    }

    function setWithExpiry(key, value, ttl) {
        const now = new Date()    
        const item = {
            value: value,
            expiry: now.getTime() + ttl,
        }
        localStorage.setItem(key, JSON.stringify(item))
    }

    function getWithExpiry(key) {
        const itemStr = localStorage.getItem(key)
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr)
        const now = new Date()
        
        if (now.getTime() > item.expiry) {            
            localStorage.removeItem(key)
            return null
        }
        return item.value
    }

    function removeClassIfExists(element, className) {
        if (element.classList.contains(className)) {
          element.classList.remove(className);
        }
      }
    function removeHrefAndAddClickListener(element, clickHandler) {
        element.removeAttribute('href');
        element.addEventListener('click', clickHandler);
      }
      function removeElementIfExists(element) {
        if (element && element.parentNode) {
          element.parentNode.removeChild(element);
        }
      }
    function addClass(element, className) {
        if (!element.classList.contains(className)) {
          element.classList.add(className);
        }
    }

    function setReadOnly(element, readOnly) {
        element.readOnly = readOnly;
    }

    function onDocumentReady() {
        return new Promise(resolve => {
        if (document.readyState === "complete") {
            resolve();
        } else {
            window.addEventListener("load", () => {
            resolve();
            });
        }
        });
    }

    function insertAfter(newElement, referenceElement) {
        if (newElement && referenceElement){
            referenceElement.parentNode.insertBefore(newElement, referenceElement.nextSibling);
        }
    }

    function setStyle(element, style, value) {
        if (element){
            element.style[style] = value;
        }
    }

    function getElement(selector) {
        var res = document.querySelectorAll(selector);
        if (res && res.length>0){
            return res[0];
        }
        return undefined;
    }

    function emptyContents(element) {
        if (element){
            while (element.firstChild) {
                element.removeChild(element.firstChild);
            }
        }
    }

    function emptyContentsBySelector(selector) {
        if (selector && selector.trim()!=''){
            var element = getElement(selector);
            emptyContents(element);
        }
    }


    function elementExists(selector) {
        return document.querySelectorAll(selector).length > 0;
    }

    function setHtmlContent(element, html) {
    element.innerHTML = html;
    }

    function createElementFromHtml(htmlString) {
        return createElementFromHTML(htmlString);
    }

    function createElementFromHTML(htmlString) {
        if (htmlString && htmlString.trim!=''){
            const div = document.createElement('div');
            div.innerHTML = htmlString.trim();
            return div.firstChild;
        }
        return undefined;
    }

    function prependElement(parent, child) {
        parent.insertBefore(child, parent.firstChild);
    }

    function appendElement(parent, child) {
        if (parent && child){
            parent.appendChild(child);
        }
    }
    
    function getUppermostSectionInMain(button) {
        const main = document.querySelector('main');
      
        let section = null;
        let currentElement = button;
      
        while (currentElement && currentElement !== main) {
          if (currentElement.tagName === 'SECTION') {
            section = currentElement;
          }
          currentElement = currentElement.parentElement;
        }
      
        return section;
    }

    function addListeners(element, eventNames, listener) {
        if (element){
            var events = eventNames.split(' ');
            for (var i=0, iLen=events.length; i<iLen; i++) {
            element.addEventListener(events[i], listener, false);
            }
        }
    }

    function removeElement(element) {
        if (element){
            element.parentNode.removeChild(element);
        }
    }


    function findAncestor (el, sel) {
        while ((el = el.parentElement) && !((el.matches || el.matchesSelector).call(el,sel)));
        return el;
    }

    //requests


    function makeGetRequest(url) {
        var options = {
            headers:{

            }
        };
        if (_prusasearchoptions.token){
            options.headers['Authorization']='Bearer '+_prusasearchoptions.token
        }
        return fetch(url,options)
            .then(response => response.json())
            .catch(error => {
                debugger;
                console.error(error);
            });
    }

    function makePostRequest(url, data) {
        return fetch(url, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .catch(error => console.error(error));
    }

    function makePutRequest(url, data) {
        return fetch(url, {
            method: 'PUT',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .catch(error => console.error(error));
    }

    function makeDeleteRequest(url) {
        return fetch(url, {
            method: 'DELETE'
        })
        .then(response => response.json())
        .catch(error => console.error(error));
    }
    function isValidUSZipCode(zipCode) {
        // US zip codes can be either 5 digits or 5 digits followed by a hyphen and 4 more digits
        const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
        return zipCodeRegex.test(zipCode);
      }
    function enableJoinSubmitButton(selection){

        var submit = document.querySelector('.join-the-club-submit');
        if (submit.classList.contains('processing')){
            return;
        }
        if (selection=='ship'){
            var state = document.querySelector('.ship input');            
            
            if (state.value){                
                removeClassIfExists(submit,'disabled');                    
                return;
            }

            addClass(submit,'disabled');            
            
            return;
        }
        
        removeClassIfExists(submit,'disabled');
    }
    function getState(zipString) {

        /* Ensure param is a string to prevent unpredictable parsing results */
        if (typeof zipString !== 'string') {
            console.error('Must pass the zipcode as a string.');
            return;
        }
      
        /* Ensure we have exactly 5 characters to parse */
        if (zipString.length !== 5) {
            console.error('Must pass a 5-digit zipcode.');
            return;
        }
      
        /* Ensure we don't parse strings starting with 0 as octal values */
        const zipcode = parseInt(zipString, 10);
      
        let st;
        let state;
      
        /* Code cases alphabetized by state */
        if (zipcode >= 35000 && zipcode <= 36999) {
            st = 'AL';
            state = 'Alabama';
        } else if (zipcode >= 99500 && zipcode <= 99999) {
            st = 'AK';
            state = 'Alaska';
        } else if (zipcode >= 85000 && zipcode <= 86999) {
            st = 'AZ';
            state = 'Arizona';
        } else if (zipcode >= 71600 && zipcode <= 72999) {
            st = 'AR';
            state = 'Arkansas';
        } else if (zipcode >= 90000 && zipcode <= 96699) {
            st = 'CA';
            state = 'California';
        } else if (zipcode >= 80000 && zipcode <= 81999) {
            st = 'CO';
            state = 'Colorado';
        } else if ((zipcode >= 6000 && zipcode <= 6389) || (zipcode >= 6391 && zipcode <= 6999)) {
            st = 'CT';
            state = 'Connecticut';
        } else if (zipcode >= 19700 && zipcode <= 19999) {
            st = 'DE';
            state = 'Delaware';
        } else if (zipcode >= 32000 && zipcode <= 34999) {
            st = 'FL';
            state = 'Florida';
        } else if ( (zipcode >= 30000 && zipcode <= 31999) || (zipcode >= 39800 && zipcode <= 39999) ) {
            st = 'GA';
            state = 'Georgia';
        } else if (zipcode >= 96700 && zipcode <= 96999) {
            st = 'HI';
            state = 'Hawaii';
        } else if (zipcode >= 83200 && zipcode <= 83999 && zipcode != 83414) {
            st = 'ID';
            state = 'Idaho';
        } else if (zipcode >= 60000 && zipcode <= 62999) {
            st = 'IL';
            state = 'Illinois';
        } else if (zipcode >= 46000 && zipcode <= 47999) {
            st = 'IN';
            state = 'Indiana';
        } else if (zipcode >= 50000 && zipcode <= 52999) {
            st = 'IA';
            state = 'Iowa';
        } else if (zipcode >= 66000 && zipcode <= 67999) {
            st = 'KS';
            state = 'Kansas';
        } else if (zipcode >= 40000 && zipcode <= 42999) {
            st = 'KY';
            state = 'Kentucky';
        } else if (zipcode >= 70000 && zipcode <= 71599) {
            st = 'LA';
            state = 'Louisiana';
        } else if (zipcode >= 3900 && zipcode <= 4999) {
            st = 'ME';
            state = 'Maine';
        } else if (zipcode >= 20600 && zipcode <= 21999) {
            st = 'MD';
            state = 'Maryland';
        } else if ( (zipcode >= 1000 && zipcode <= 2799) || (zipcode == 5501) || (zipcode == 5544 ) ) {
            st = 'MA';
            state = 'Massachusetts';
        } else if (zipcode >= 48000 && zipcode <= 49999) {
            st = 'MI';
            state = 'Michigan';
        } else if (zipcode >= 55000 && zipcode <= 56899) {
            st = 'MN';
            state = 'Minnesota';
        } else if (zipcode >= 38600 && zipcode <= 39999) {
            st = 'MS';
            state = 'Mississippi';
        } else if (zipcode >= 63000 && zipcode <= 65999) {
            st = 'MO';
            state = 'Missouri';
        } else if (zipcode >= 59000 && zipcode <= 59999) {
            st = 'MT';
            state = 'Montana';
        } else if (zipcode >= 27000 && zipcode <= 28999) {
            st = 'NC';
            state = 'North Carolina';
        } else if (zipcode >= 58000 && zipcode <= 58999) {
            st = 'ND';
            state = 'North Dakota';
        } else if (zipcode >= 68000 && zipcode <= 69999) {
            st = 'NE';
            state = 'Nebraska';
        } else if (zipcode >= 88900 && zipcode <= 89999) {
            st = 'NV';
            state = 'Nevada';
        } else if (zipcode >= 3000 && zipcode <= 3899) {
            st = 'NH';
            state = 'New Hampshire';
        } else if (zipcode >= 7000 && zipcode <= 8999) {
            st = 'NJ';
            state = 'New Jersey';
        } else if (zipcode >= 87000 && zipcode <= 88499) {
            st = 'NM';
            state = 'New Mexico';
        } else if ( (zipcode >= 10000 && zipcode <= 14999) || (zipcode == 6390) || (zipcode == 501) || (zipcode == 544) ) {
            st = 'NY';
            state = 'New York';
        } else if (zipcode >= 43000 && zipcode <= 45999) {
            st = 'OH';
            state = 'Ohio';
        } else if ((zipcode >= 73000 && zipcode <= 73199) || (zipcode >= 73400 && zipcode <= 74999) ) {
            st = 'OK';
            state = 'Oklahoma';
        } else if (zipcode >= 97000 && zipcode <= 97999) {
            st = 'OR';
            state = 'Oregon';
        } else if (zipcode >= 15000 && zipcode <= 19699) {
            st = 'PA';
            state = 'Pennsylvania';
        } else if (zipcode >= 300 && zipcode <= 999) {
            st = 'PR';
            state = 'Puerto Rico';
        } else if (zipcode >= 2800 && zipcode <= 2999) {
            st = 'RI';
            state = 'Rhode Island';
        } else if (zipcode >= 29000 && zipcode <= 29999) {
            st = 'SC';
            state = 'South Carolina';
        } else if (zipcode >= 57000 && zipcode <= 57999) {
            st = 'SD';
            state = 'South Dakota';
        } else if (zipcode >= 37000 && zipcode <= 38599) {
            st = 'TN';
            state = 'Tennessee';
        } else if ( (zipcode >= 75000 && zipcode <= 79999) || (zipcode >= 73301 && zipcode <= 73399) ||  (zipcode >= 88500 && zipcode <= 88599) ) {
            st = 'TX';
            state = 'Texas';
        } else if (zipcode >= 84000 && zipcode <= 84999) {
            st = 'UT';
            state = 'Utah';
        } else if (zipcode >= 5000 && zipcode <= 5999) {
            st = 'VT';
            state = 'Vermont';
        } else if ( (zipcode >= 20100 && zipcode <= 20199) || (zipcode >= 22000 && zipcode <= 24699) || (zipcode == 20598) ) {
            st = 'VA';
            state = 'Virginia';
        } else if ( (zipcode >= 20000 && zipcode <= 20099) || (zipcode >= 20200 && zipcode <= 20599) || (zipcode >= 56900 && zipcode <= 56999) ) {
            st = 'DC';
            state = 'Washington DC';
        } else if (zipcode >= 98000 && zipcode <= 99499) {
            st = 'WA';
            state = 'Washington';
        } else if (zipcode >= 24700 && zipcode <= 26999) {
            st = 'WV';
            state = 'West Virginia';
        } else if (zipcode >= 53000 && zipcode <= 54999) {
            st = 'WI';
            state = 'Wisconsin';
        } else if ( (zipcode >= 82000 && zipcode <= 83199) || zipcode == 83414 ) {
            st = 'WY';
            state = 'Wyoming';
        } else {
            st = 'none';
            state = 'none';
            console.log('No state found matching', zipcode);
        }
      
        /* Return `state` for full name or `st` for postal abbreviation */
        return state;
      }      
    function submitToCheckout(parent,theButton){

        if (theButton.classList.contains('disabled') || theButton.classList.contains('processing')){
            return;
        }

        var submit = parent.querySelector('.join-the-club-submit');
        var popup = parent.querySelector('.join-club-popup');
        var clubName = popup.getAttribute('data-club-name');
        var selection = popup.getAttribute('data-id');

        var option = "";
        if (selection=="pickup"){
            option = "no-champagne";
        }else{
            var zipcode = document.querySelector('.ship input').value;
            var error_zip = document.querySelector("#zip-error");
            if(isValidUSZipCode(zipcode)){
                error_zip.style.display = "none";
                switch (getState(zipcode)) {                
                    case "Iowa":
                    case "Louisiana":
                    case "Massachusetts": 
                    case "Michigan": 
                    case "Missouri": 
                    case "Tennessee": 
                    case "Texas": 
                    case "Wisconsin": 
                        option="no-champagne";
                        break;
                    default:
                        option="ship-champagne";
                        break;
                }
            }
            else{
                error_zip.style.display = "block";
            }
            
        }
        setWithExpiry('club-shipping-type',selection,60*60*1000);        
        addClass(theButton,"processing");

        addClass(popup,'processing');
        
        window.clubButtonClickInterval = setInterval(() => {
            
            var targetButton = parent.querySelector('.c7-club-join-button[data-club-name="'+clubName+'"][data-shipping-type="'+option+'"] a.c7-btn.c7-btn--primary');
            if(targetButton == null){
                targetButton = parent.querySelector('.c7-club-join-button[data-club-name="'+clubName+'"][data-shipping-type="'+option+'"] a.c7-button');
            }
            //console.log('clicked on '+targetButton.parentElement.getAttribute('data-club-slug'));
            if (targetButton){
                clearInterval(window.clubButtonClickInterval);
                targetButton.click();
            }
        }, 100);
    }
    function is_usZipCode(str)
    {
    regexp = /^[0-9]{5}(?:-[0-9]{4})?$/;
    
            if (regexp.test(str))
            {
                return true;
            }
            else
            {
                return false;
            }
    }

    function showClubSelectionPopup(clubName,parent){
        
        removeElementIfExists(document.querySelector('.join-club-popup-fullscreen-overlay'));
        var popup = `
            <div class="join-club-popup-fullscreen-overlay">        
                <div class="join-club-popup" data-club-name="${clubName}" data-id="pickup">                
                    <div class="spinner hidden">
                        <span>Processing</span>
                        <svg fill="#9e7952" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <style>.spinner_ajPY{transform-origin:center;animation:spinner_AtaB .75s infinite linear}@keyframes spinner_AtaB{100%{transform:rotate(360deg)}}
                            </style>
                            <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25"/>
                            <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" class="spinner_ajPY"/>
                        </svg>
                    </div>
                    <div class="header">
                        <a class="close-join-club-popup" onclick="document.querySelector('.join-club-popup-fullscreen-overlay').style.display = 'none';">
                            <svg aria-hidden="true" focusable="false" role="presentation" class="icon icon-close" viewBox="0 0 1024 1024" fill="#9e7952"><path d="M446.174 512L13.632 79.458c-18.177-18.177-18.177-47.649 0-65.826s47.649-18.177 65.826 0L512 446.174 944.542 13.632c18.177-18.177 47.649-18.177 65.826 0s18.177 47.649 0 65.826L577.826 512l432.542 432.542c18.177 18.177 18.177 47.649 0 65.826s-47.649 18.177-65.826 0L512 577.826 79.458 1010.368c-18.177 18.177-47.649 18.177-65.826 0s-18.177-47.649 0-65.826L446.174 512z"></path></svg>
                        </a>
                        <label>
                            Select your shipping option
                        </label>
                    </div>
                    <div class="options">
                        <button class="pickup content-v2__cta--primary split-promo__cta split-promo__cta--primary active" data-id="pickup">
                            Pickup
                        </button>
                        <button class="ship content-v2__cta--primary split-promo__cta split-promo__cta--primary" data-id="ship">
                            Ship
                        </button>
                    </div>
                    <div class="additional">
                        <div class="pickup">
                            <label>
                            Pickup at:<br>
                            8445 Silverado Trail<br>
                            Napa, CA 94558<br>
                            800-686-6272
                            </label>
                        </div>
                        <div class="ship">
                            <label>Enter Zip Code for shipment:</label>
                            <br>
                            <label class="subtext">Zip Code</label>
                            <input inputmode="numeric" type="text" pattern="[1-9][0-9]{4}" minlength="5" maxlength="5" placeholder="Zip Code">
                            <label id="zip-error">Invalid Zip Code</label>
                        </div>
                    </div>
                    <div class="legal">
                        <p>
                            You must be over the age of 21 to join Club Mumm Napa. State shipping restrictions apply.
                        </p>
                    </div>
                    <div class="action">
                        <button class="join-the-club-submit content-v2__cta--primary split-promo__cta split-promo__cta--primary">
                            Join now
                        </button>
                    </div>
                </div>        
            </div>                
        `;

        appendElement(parent,createElementFromHTML(popup));
        console.log("here")
        




        var buttons = document.querySelectorAll('.join-club-popup .options  button');

        buttons.forEach((button)=>{
            button.addEventListener('click',function(e){

                var buttons = document.querySelectorAll('.join-club-popup .options  button');
                buttons.forEach((e)=>{
                    removeClassIfExists(e,'active');
                });
                addClass(button,'active');
                var popup = document.querySelector('.join-club-popup');
                popup.setAttribute('data-id',button.getAttribute('data-id'));

                enableJoinSubmitButton(button.getAttribute('data-id'));
            });
        });

        var stateSelector = document.querySelector('.ship input');

        stateSelector.addEventListener('change',function(e){
            var popup = document.querySelector('.join-club-popup');
            var selection = popup.getAttribute('data-id');
            enableJoinSubmitButton(selection);
        });

        var submitButton = parent.querySelector('button.join-the-club-submit');
        submitButton.addEventListener('click',function(e){
            var parentElementForThePopup = document.querySelector('main.main-container')
            
            submitToCheckout(parentElementForThePopup,e.target);
        });
        try{
            var closePopup = getElement('.close-join-club-popup');
            closePopup.addEventListener('click',function(e){            
                removeElementIfExists(document.querySelector('.join-club-popup-fullscreen-overlay'));
            });
        }
        catch{
            
        }
    }

    const noChampagneStates=[
        'Texas',
        'Tennessee',
        'Ohio',
        'Missouri'
    ];

    const states = [
        'Alabama',
        'Arizona',
        'Arkansas',
        'California',
        'Colorado',
        'Connecticut',
        'Delaware',
        'Florida',
        'Georgia',
        'Hawaii',
        'Idaho',
        'Illinois',
        'Indiana',
        'Iowa',
        'Kansas',
        'Kentucky',
        'Louisiana',
        'Maine',
        'Maryland',
        'Massachusetts',
        'Michigan',
        'Minnesota',
        'Mississippi',
        'Missouri',
        'Montana',
        'Nebraska',
        'Nevada',
        'New Hampshire',
        'New Jersey',
        'New Mexico',
        'New York',
        'North Carolina',
        'North Dakota',
        'Ohio',
        'Oklahoma',
        'Oregon',
        'Pennsylvania',
        'Rhode Island',
        'South Carolina',
        'South Dakota',
        'Tennessee',
        'Texas',
        'Utah',
        'Vermont',
        'Virginia',
        'Washington',
        'West Virginia',
        'Wisconsin',
        'Wyoming'
    ]


    console.log('checkout script loaded');
    function getQueryParams(url) {
        const paramArr = url.slice(url.indexOf('?') + 1).split('&');
        const params = {};
        paramArr.map(param => {
            const [key, val] = param.split('=');
            params[key] = decodeURIComponent(val);
        })
        return params;
    }

    
    
    var clubJoins = document.querySelectorAll('div.c7-club-join-button-replaced');



    if (clubJoins && clubJoins.length>0){   
        clubJoins.forEach(theButtonWrapper=>{            
            var clubName = theButtonWrapper.getAttribute('data-club-name');
            var theButton = theButtonWrapper.querySelector('a.join-club-button');

            theButton.addEventListener('click',function(e){
                //console.log('clicked '+e.target.getAttribute('data-club-slug'));
                var parentElementForThePopup = document.querySelector('main.main-container');//  getUppermostSectionInMain(theButtonWrapper);

                
                showClubSelectionPopup(clubName,parentElementForThePopup);
            });
        });
    }
    if (window.location.href.includes('/checkout/club/delivery')){
        window.checkC7Loaded = setInterval(function(){
            var shippingTab = document.querySelector('#c7-delivery-shipping-tab');
            var pickupTab = document.querySelector('#c7-delivery-pickup-tab');

            if (shippingTab && pickupTab){
                let preferredShipmentType = getWithExpiry('club-shipping-type');        
                
                if (preferredShipmentType && preferredShipmentType=='pickup'){                    

                        pickupTab.click();
                        setStyle(shippingTab,'display','none');               
                        addClass(shippingTab,'hidden');
                        

                        // setTimeout(function(){
                        //     pickupTab.click();
                        //     setStyle(shippingTab,'display','none');               
                        //     addClass(shippingTab,'hidden');
                        // },300);

                }else if (preferredShipmentType && preferredShipmentType=='ship' ){                    
                    
                    shippingTab.click();
                    setStyle(pickupTab,'display','none');            
                    addClass(pickupTab,'hidden');

                    // setTimeout(function(){
                    //     shippingTab.click();
                    //     setStyle(pickupTab,'display','none');            
                    //     addClass(pickupTab,'hidden');
                    // },300);

                }
                clearInterval(window.checkC7Loaded);
            }
        },100);
    }

    if (window.location.href.includes('/checkout/club/')){
        //we're on the checkout club page
        if (preferredShipmentType && preferredShipmentType=='pickup'){                    
            if(isCommerce7V2()){
                window.location.replace("/checkout/club/delivery");

            }
        }
        
        window.checkC7Loaded = setInterval(function(){
            var shippingTab = document.querySelector('#c7-delivery-shipping-tab');
            var pickupTab = document.querySelector('#c7-delivery-pickup-tab');

            if (shippingTab && pickupTab){
                let preferredShipmentType = getWithExpiry('club-shipping-type');        
                
                if (preferredShipmentType && preferredShipmentType=='pickup'){                    

                        pickupTab.click();
                        setStyle(shippingTab,'display','none');               
                        addClass(shippingTab,'hidden');
                        

                        // setTimeout(function(){
                        //     pickupTab.click();
                        //     setStyle(shippingTab,'display','none');               
                        //     addClass(shippingTab,'hidden');
                        // },300);

                }else if (preferredShipmentType && preferredShipmentType=='ship' ){                    
                    
                    shippingTab.click();
                    setStyle(pickupTab,'display','none');            
                    addClass(pickupTab,'hidden');

                    // setTimeout(function(){
                    //     shippingTab.click();
                    //     setStyle(pickupTab,'display','none');            
                    //     addClass(pickupTab,'hidden');
                    // },300);

                }
                clearInterval(window.checkC7Loaded);
            }
        },100);
        
        
    }

    if (window.location.href.includes('https://dev-mumm-sparkling.pantheonsite.io/checkout/cart') || window.location.href.includes('https://dev.mummnapa.com/checkout/cart')){
        function waitAltPayments(){
            var alt_payments = document.querySelectorAll(".c7-checkout__alt-payment");
            if(alt_payments.length > 0){
                alt_payments[0].style.display = 'block';
            }
            else{
                setTimeout(function(){
                waitAltPayments();
            }, 100);
            }
          }
          waitAltPayments();
    }
    if (window.location.href.includes('https://dev-mumm-sparkling.pantheonsite.io/')){
        function waitDataLayer(){
            if(typeof dataLayer !== 'undefined' && dataLayer.push){
                dataLayer.push({
                    event: "evidonConsentGiven",
                    consentCategories: "analytics provider,tag manager,data aggregator/supplier,advertiser",
                    consentVendors: "casale-media,addthis,facebook-connect,google-analytics,evidon,facebook,yahoo,d" +
                                    "oubleclick,doubleclick-bid-manager-formerly-invite-media,pubmatic,google-adwor" +
                                    "ds,krux-digital,the-trade-desk,tealium,bazaarvoice,pinterest,vimeo,moat,google" +
                                    "-tag-manager,iponweb,blueconic,sleeknote,google-maps,googlefonts,googlerecaptcha"
                  })
            }
            else{
                setTimeout(function(){
                    waitDataLayer();
            }, 100);
            }
          }
          waitDataLayer();
        
    }
    function getElement(parent_element, path_array){
        let el = parent_element;
        for(let depth = 0; depth < path_array.length; depth++){
            el = el.children[path_array[depth]]
        }
        return el;
    }
    function iterateMaxHeight(element, parent_element, path, ignore=false) {
        let el;
        let max_height = 0;
        for(let i = 0; i < parent_element.children.length; i++){
            el = getElement(parent_element.children[i], path);
            if (el.offsetHeight > max_height){
                max_height = el.offsetHeight;
            }
        }
        for(let i = 0; i < parent_element.children.length; i++){
            el = getElement(parent_element.children[i], path);
            if(ignore == false){
                el.style.marginBottom = String(max_height - el.offsetHeight) + 'px';
            }
            else{
                el.style.marginBottom = '0px';
            }
            
        }
    }
    function findMaxHeight(element, parent_element, path, ignore = false) {
        if (element.children.length === 0) {
            let el;
            let max_height = 0;
            iterateMaxHeight(element, parent_element, path, ignore);
        } else {
            let aux_path;
            for (let i = 0; i < element.children.length; i++) {
                aux_path = path.slice(0);
                aux_path.push(i);
                findMaxHeight(element.children[i], parent_element, aux_path, ignore);
                iterateMaxHeight(element, parent_element, path, ignore);
            }
        }
    }
    function cartTabletMobile(){
        let cart = document.querySelector("#c7-side-cart")
        let nav = document.querySelector(".header-nav")
        try{
            if (cart.classList.contains("c7-side-cart--visible")){
                nav.classList.add("active-cart")
            }
            else{
                nav.classList.remove("active-cart")
            }
        }
        catch(e){
            console.log(e)
        }
        
        setTimeout(function(){
            cartTabletMobile();
        }, 100);
    }
    cartTabletMobile();
    function fixHeights(){
        let parentElementSameHeightGrid = document.querySelectorAll(".set-same-height-grid-elements .group-container__grid-container")
        let ignore_height = false;
        if(window.innerWidth < 744){
            ignore_height = true;
        }
        parentElementSameHeightGrid.forEach((parentElement) =>{
            findMaxHeight(parentElement.children[0],parentElement,[],ignore_height)
        })
        let parentElementSameHeightCarousel = document.querySelectorAll(".set-same-height-grid-elements .swiper-wrapper")
        parentElementSameHeightCarousel.forEach((parentElement) =>{
            findMaxHeight(parentElement.children[0],parentElement,[])
        })
        setTimeout(function(){
            fixHeights();
        }, 100);
    }
    fixHeights();
    function fixExploreRangeHeights(){
        let exploreRange = document.querySelector(".explore-the-range")
        try{
            let ignore_height = false;
            if(window.innerWidth < 744){
                ignore_height = true;
            }
            let wineDescriptions = exploreRange.querySelectorAll(".post-info__post-info-description")
            let maxHeight = 0;
            wineDescriptions.forEach((wine)=>{
                if(wine.offsetHeight > maxHeight){
                    maxHeight = wine.offsetHeight
                }
            })
            wineDescriptions.forEach((wine)=>{
                wine.style.marginBottom = (maxHeight - wine.offsetHeight).toString() + 'px'
            })
        }
        catch{

        }
        setTimeout(function(){
            fixExploreRangeHeights();
        }, 100);
    }
    fixExploreRangeHeights();
    function setSameHeightEventsCarousel(){
        try{
            let eventCarousels = document.querySelectorAll(".event-carousel")
            eventCarousels.forEach((carousel) => {
                let eventsCTA = carousel.querySelectorAll(".post-info__post-link.post-info__info-item.post-info__cta")
                let maxHeight = 0
                eventsCTA.forEach((event)=>{
                    let parentHeight = event.parentElement.parentElement.offsetHeight
                    if(parentHeight > maxHeight){
                        maxHeight = parentHeight
                    }
                })
                eventsCTA.forEach((event)=>{
                    let parentHeight = event.parentElement.parentElement.offsetHeight
                    event.style.marginTop = (maxHeight-parentHeight).toString() + 'px'
                })
            })
        }
        catch{

        }
        setTimeout(function(){
            setSameHeightEventsCarousel();
        }, 100);
    }
    setSameHeightEventsCarousel();
    function setSameHeightBottlesPDP(){
        try{
            let wineCarousels = document.querySelectorAll(".post-type-filter-grid-v2--product")
            let columnNumber = 2;
        
            wineCarousels.forEach((carousel) => {
                if (window.screen.width < 743){
                    columnNumber = carousel.style.getPropertyValue("--columns-mobile")
                }
                else if (window.screen.width < 1439){
                    columnNumber = carousel.style.getPropertyValue("--columns-portrait")
                } 
                else if (window.screen.width < 743){
                    columnNumber = carousel.style.getPropertyValue("--columns-desktop")
                } 
                let addtoCartCTAs = carousel.querySelectorAll(".post-type-filter-grid-v2__product-c7-slug.post-type-filter-grid-v2__info-item")
                for(let i=0;i<Math.ceil(addtoCartCTAs.length/columnNumber);i++){
                    let maxTitleHeight = 0
                    for(let j=0; j<columnNumber; j++){
                        if(i*columnNumber+j < addtoCartCTAs.length){
                            let currAddToCart = addtoCartCTAs[i*columnNumber+j]
                            let titleHeight = currAddToCart.parentElement.querySelector(".post-type-filter-grid-v2__post-title").offsetHeight
                            if(titleHeight > maxTitleHeight){
                                maxTitleHeight = titleHeight
                            }
                        }
                    }
                    for(let j=0; j<columnNumber; j++){
                        if(i*columnNumber+j < addtoCartCTAs.length){
                            let currAddToCart = addtoCartCTAs[i*columnNumber+j]
                            let titleHeight = currAddToCart.parentElement.querySelector(".post-type-filter-grid-v2__post-title").offsetHeight
                            let BVDiff = 0;
                            if(!currAddToCart.parentElement.querySelector(".bv_main_container")){
                                BVDiff = 25.6;
                            }
                            currAddToCart.style.marginTop = (maxTitleHeight-titleHeight+BVDiff).toString() + 'px'
                        }
                    }
                }
            })
        }
        catch{
            
        }
        setTimeout(function(){
            setSameHeightBottlesPDP();
        }, 100);
    }
    setSameHeightBottlesPDP()
    function setSameHeightBottlesRecommendedProducts(){
        try{
            let wineCarousels = document.querySelectorAll(".recommended-products .carousel-multi-layout-v2")
        
            wineCarousels.forEach((carousel) => {
                let addtoCartCTAs = carousel.querySelectorAll(".post-info__c7-add-to-cart.post-info__info-item")
                let maxTitleHeight = 0
                for(let i=0;i<addtoCartCTAs.length;i++){
                    let currAddToCart = addtoCartCTAs[i]
                    let titleHeight = currAddToCart.parentElement.querySelector(".post-info__title").offsetHeight
                    if(titleHeight > maxTitleHeight){
                        maxTitleHeight = titleHeight
                    }
                }
                for(let i=0;i<addtoCartCTAs.length;i++){
                    let currAddToCart = addtoCartCTAs[i]
                    let titleHeight = currAddToCart.parentElement.querySelector(".post-info__title").offsetHeight
                    let BVDiff = 0;
                    if(!currAddToCart.parentElement.querySelector(".bv_main_container")){
                        BVDiff = 25.6;
                    }
                    currAddToCart.style.marginTop = (maxTitleHeight-titleHeight+BVDiff).toString()+'px';
                }
            })
        }
        catch{
            
        } 
        setTimeout(function(){
            setSameHeightBottlesRecommendedProducts();
        }, 100);
    }
    setSameHeightBottlesRecommendedProducts()

    function setSameHeightBlogArticles(){
        try{
            let wineCarousels = document.querySelectorAll(".post-type-filter-grid-v2--article")
            let columnNumber = 2;
        
            wineCarousels.forEach((carousel) => {
                if (window.screen.width < 743){
                    columnNumber = carousel.style.getPropertyValue("--columns-mobile")
                }
                else if (window.screen.width < 1439){
                    columnNumber = carousel.style.getPropertyValue("--columns-portrait")
                } 
                else if (window.screen.width >= 1439){
                    columnNumber = carousel.style.getPropertyValue("--columns-desktop")
                } 
                let addtoCartCTAs = carousel.querySelectorAll(".post-type-filter-grid-v2__cta-span")
                for(let i=0;i<Math.ceil(addtoCartCTAs.length/columnNumber);i++){
                    let maxContentHeight = 0
                    for(let j=0; j<columnNumber; j++){
                        if(i*columnNumber+j < addtoCartCTAs.length){
                            let currAddToCart = addtoCartCTAs[i*columnNumber+j]
                            let titleHeight = currAddToCart.parentElement.parentElement.parentElement.querySelector(".post-type-filter-grid-v2__post-title").offsetHeight
                            let descriptionHeight = currAddToCart.parentElement.parentElement.parentElement.querySelector(".post-type-filter-grid-v2__post-info-description").offsetHeight
                            let currentHeight = descriptionHeight + titleHeight;
                            if(currentHeight > maxContentHeight){
                                maxContentHeight = currentHeight
                            }
                        }
                    }
                    for(let j=0; j<columnNumber; j++){
                        if(i*columnNumber+j < addtoCartCTAs.length){
                            let currAddToCart = addtoCartCTAs[i*columnNumber+j]
                            let titleHeight = currAddToCart.parentElement.parentElement.parentElement.querySelector(".post-type-filter-grid-v2__post-title").offsetHeight
                            let descriptionHeight = currAddToCart.parentElement.parentElement.parentElement.querySelector(".post-type-filter-grid-v2__post-info-description").offsetHeight
                            let currentHeight = descriptionHeight + titleHeight;
                            currAddToCart.style.marginBottom = (maxContentHeight-currentHeight).toString() + 'px'
                        }
                    }
                }
            })
        }
        catch{
            
        }
        setTimeout(function(){
            setSameHeightBlogArticles();
        }, 100);
    }
    setSameHeightBlogArticles()


    function checkParallaxHeight(){
        var elem = document.querySelector('.parallax');
        if(elem){
            if(elem.offsetHeight > 0 && window.screen.width >= 992){
                if(window.screen.width >= 1440){
                    document.documentElement.style.setProperty('--parallax-height', '100vh');
                }
                else{
                    document.documentElement.style.setProperty('--parallax-height', elem.offsetHeight + 'px');
                }
            }
            setTimeout(function(){
                checkParallaxHeight();
            }, 100);
        }
    }
    checkParallaxHeight();

    window.onscroll = function() {
        var scroll = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;
        if(scroll > window.screen.height*1.2){
            document.documentElement.style.setProperty('--display-parallax', "none");    
        }
        else{
            document.documentElement.style.setProperty('--display-parallax', "grid");    
        }
        document.documentElement.style.setProperty('--scroll-var', scroll + "px");
    }


    function waitForElm(selector) {
        return new Promise(resolve => {
            if (document.querySelector(selector)) {
                return resolve(document.querySelector(selector));
            }
            const observer = new MutationObserver(mutations => {
                if (document.querySelector(selector)) {
                    observer.disconnect();
                    resolve(document.querySelector(selector));
                }
            });
            observer.observe(document.body, {
                childList: true,
                subtree: true
            });
        });
    }


    waitForElm('.carousel-multi-layout-v2__inner-container>.swiper-pagination.swiper-pagination-clickable').then((elm) => {
        const $ = jQuery;
        var original_pagination = $('.carousel-multi-layout-v2__inner-container>.swiper-pagination.swiper-pagination-clickable')
        var slides = $('.carousel-multi-layout-v2__slide .single-content-box__inner-container')
        slides.each(function(index) {
        original_pagination.clone(true).appendTo($(this))
        $(this).find('.swiper-pagination-bullet').removeClass('swiper-pagination-bullet-active')
        $(this).find('.swiper-pagination-bullet:nth-child('+ String(index+1) +')').addClass('swiper-pagination-bullet-active')
        })
    })  
}
document.addEventListener('DOMContentLoaded', function() {
    var scroll = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;
    document.documentElement.style.setProperty('--scroll-var', scroll + "px");
})

document.addEventListener('DOMContentLoaded', function() {
    var elem = document.querySelector('.parallax');
    var element_height = '100vh'
    if(elem){
        if(window.screen.width >= 1024){
            document.documentElement.style.setProperty('--display-parallax', "grid");  
            elem.style.display = 'var(--display-parallax)';
            elem.style.setProperty('--element-height',element_height);
            document.documentElement.style.setProperty('--parallax-height', elem.offsetHeight);    
            elem.parentElement.children[1].style.marginTop = "var(--parallax-height)";
        }
        else{
        }
    }
})

function removeProductLinksFromShipment(){
        var club_product_links = document.querySelectorAll(".c7-account .c7-order-items.c7-order-items--shipment .c7-order-item__title a");
        club_product_links.forEach(function(link) {
            link.removeAttribute("href");
        })
    
        setTimeout(function(){
                removeProductLinksFromShipment();
        }, 100);
}

if (window.location.href.includes('profile')){
    removeProductLinksFromShipment();  
}

export function ttfb() {
	new PerformanceObserver((entryList) => {
		const [pageNav] = entryList.getEntriesByType('navigation');

		pushData(pageNav.responseStart);
		console.log(`TTFB: ${pageNav.responseStart}`);
	}).observe({type: 'navigation', buffered: true});
}

function pushData(time) {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({ttfb: time});
	console.log('ttfb sent');
}

export default function(){
    var zendesk_script = document.createElement('script');
    zendesk_script.setAttribute('src','https://static.zdassets.com/ekr/snippet.js?key=5c95e74f-9798-4729-9e4e-fe2475b9e482');
    zendesk_script.setAttribute('id','ze-snippet');
    zendesk_script.setAttribute('loading','lazy');
    document.body.appendChild(zendesk_script);
    var settings_script = document.createElement('script');
    settings_script.type = 'text/javascript';
    settings_script.text = `
      window.zESettings = {
        webWidget: {
          chat: {
            departments: {
              enabled: []
            }
          }
        }
      };
    `;

    // Append the zESettings script to the body
    document.body.appendChild(settings_script);
}